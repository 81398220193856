import React, { Component } from 'react';
import './App.css';
import './NeoloadPortal.css';
import './VzTabs.css';
import { withAuthenticator, AmplifySignOut } from '@aws-amplify/ui-react';
import { API, Auth, StorageClass } from 'aws-amplify';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import * as mutations from './graphql/mutations';
import {Column, Table} from 'react-virtualized';
import MaterialTable from "material-table";
import { forwardRef } from 'react';
import './FontawesomeIcons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

import 'react-virtualized/styles.css'; // only needs to be imported once
import vzlogo from './vz-logo.png'

const utils = {
  // create an array of numbers between min and max (edges included). 
  range: (min, max) => Array.from({ length: max - min + 1 }, (_, i) => min + i),
};

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  };

const maxHostUpdate = 20;
const minHostUpdate = 0;

class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
            dataSource: {},
            userInfo: ''
		};
    }
    
    addHost = () => {
    };

    async componentDidMount() {
        //Put stuff for userInfo here
        try {
            const userInfo = await Auth.currentUserInfo();
            console.log('userInfo: ', userInfo);
            this.setState({userInfo: userInfo});
        } catch (err) {
            console.log({err});
        }
    }

	render() {
        return (
            <div className={`app-item `}>
                
                
                <Tabs selectedTabClassName="selected-tab">
                    <div className="top">
                        <Header/>
                        <TabList className="tab-list">
                            <Tab className="tab">Launch</Tab>
                            <Tab className="tab">Stack Status</Tab>
                            <Tab className="tab">Load Generators</Tab>
                            <Tab className="tab">Terminate</Tab>
                        </TabList>
                    </div>

                    <div className={`tab-panel`}>
                        <TabPanel>
                            <HostForm onSubmit={this.addHost} userInfo={this.state.userInfo}/>
                        </TabPanel>
                        <TabPanel>
                            <StackStatus />
                        </TabPanel>
                        <TabPanel>
                            <LoadGenerators />
                        </TabPanel>
                        <TabPanel>
                            <Terminate userInfo={this.state.userInfo}/>
                        </TabPanel>
                    </div>
                </Tabs>
                
            </div>
        );
	}
}

class Header extends Component {
    constructor(props) {
        super(props);
    }

    render () {
        return (
            <div className={`top-banner`}>
                <img src={vzlogo} className={`vz-image`} />
                <AmplifySignOut className={`sign-out`}/>
            </div>

        );
    }
}

class LoadGenerators extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stackName : '',
            instances: [],
            instanceRows: 1,
            addTextStr: '',
            loading: false,
            loaded: false
        };
    }

    //Eventually we will do a regex check on the text box before even making the GET request.
    lgStackInputHandler = event => this.setState({stackName: event.target.value});
    lgStackInputTrim = event => this.setState({stackName: event.target.value.trim()});

    setInstanceRows = length => {
        if (length > 25){
            this.setState({instanceRows: 25});
        } else {
            this.setState({instanceRows: length});
        }
    };

    handleSubmit = async (event) => {
        event.preventDefault();
        
        const lgInit = {
            body: {
                StackName: this.state.stackName,
              }
        };
        console.log('lgInit: ', lgInit);
        let response;
        let newInstances;
        try {
            this.setState({loading: true});
            response = await API.post('instances', '/instances', lgInit);
            //response = [{PublicIpAddress: "3.81.10.162", Region: "Northern Virgina", InstanceType: "t3.2xlarge", State: "running"},
            //{PublicIpAddress: "54.162.156.128", Region: "Northern Virgina", InstanceType: "t3.2xlarge", State: "running"}];
            this.setState({
                loading: false, 
                loaded: true
            });
            console.log('response: ', response);
            //return response;

        } catch (err) {
            console.log({err});
            alert("For some reason your termination request failed. Please contact the administrator.");
        }

        newInstances = response.map((instance) => {
            return {
                ipAddress: instance.PublicIpAddress,
                type: instance.InstanceType,
                state: instance.State,
                addText: instance.PublicIpAddress.concat(':443'),
                region: instance.Region
            }
        });

        let newAddTextStr = '';

        response.forEach(instance => {
            newAddTextStr += instance.PublicIpAddress.concat(':443\n')
        });

        this.setInstanceRows(newInstances.length);
        console.log("Instances length: ", newInstances.length )

        console.log("newInstances: ", newInstances);

        this.setState({
            instances: newInstances,
            addTextStr: newAddTextStr,
        });
        
    };

    render() {

        const {loading, loaded} = this.state;
        return (

            <div>
                <form onSubmit={this.handleSubmit}>
                    <div className={`section-heading`}>Neoload Load Generators</div>
                    <div className={`tab-content content-item`}>         
                        <label>Enter Stack Name for Load Generator Information: {' '}
                            <input
                                className={`textbox`}
                                type="text"
                                value={this.state.stackName}
                                onChange={this.lgStackInputHandler}
                                onBlur={this.lgStackInputTrim}
                            />
                        </label> {' '}
                        <button className={`button`}>Get Load Generators</button>
                    </div>
                </form>

                {!loaded && loading && <div className="loading"><FontAwesomeIcon  icon="spinner" spin/></div>}
                {loaded && !loading &&
                <div className={`lg-content`}>
                    <div className={`table-cell lg-table`}>
                        <MaterialTable
                            icons={tableIcons}
                            title=""
                            columns={[
                                {title: 'IP Address', field: 'ipAddress'},
                                {title: 'Region', field: 'region'},
                                {title: 'Type', field: 'type'},
                                {title: 'Status', field: 'state',
                                    render: rowData => {
                                        return rowData.state == "pending" ? <p style={{ color: "#FF8c00", fontWeight: "bold" }}>{rowData.state}</p> :
                                            rowData.state == "running" ? <p style={{ color: "#008240", fontWeight: "bold" }}>{rowData.state}</p> :
                                                <p style={{ color: "#cd040b", fontWeight: "bold" }}>{rowData.state}</p>
                                    }},
                            ]}
                            data={this.state.instances}
                            options={{
                                headerStyle: {
                                    backgroundColor: '#000000',
                                    color: '#FFF',
                                    fontFamily: 'Neue Haas Grotesk Text Pro',
                                    fontWeight: 'bold'
                                }
                            }}
                        />
                    </div>
                    <div className={`ip-text-box`}>
                        <div className={`ip-text-box-label`}>Text to Add to Controller</div>
                        <textarea
                            className={`ip-text-box-text`}
                            value={this.state.addTextStr || ""}
                            rows={this.state.instanceRows}
                            cols={20}
                        />
                    </div>
                </div>}


               

                
            </div>
        );
    }
}


class StackStatus extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stacks: [],
            loading: false,
            refresh: false
        }
    }

    getStatus = async () => {
        let stacksResponse;
        try {
            this.setState({refresh: false});
            this.setState({loading: true});
            stacksResponse = await API.get('stackstatus', '/stackstatus');
            /*stacksResponse = [
                {StackName: "Fqhv-Cxt-Neoload2020-11-12T15-25-46", StackStatus: "running", UserName: "v529879", TimeCreated: "2020-11-12 15:25:49.876563", NumLgs: "2", DisplayRegion: "Northern Virginia"},
                {StackName: "Fqhv-Cxt-Neoload2020-11-11T17-19-00", StackStatus: "pending", UserName: "v529879", TimeCreated: "2020-11-11 17:19:03.338327", NumLgs: "2", DisplayRegion: "Northern Virginia"},
                {StackName: "Fqhv-Cxt-Neoload2020-11-11T15-37-14", StackStatus: "pending", UserName: "v529879", TimeCreated: "2020-11-11 15:37:17.879704", NumLgs: "2", DisplayRegion: "Northern Virginia"},
                {StackName: "Fqhv-Cxt-Neoload2020-11-10T22-30-58", StackStatus: "terminated", UserName: "v529879", TimeCreated: "2020-11-10 22:31:02.465658", NumLgs: "2", DisplayRegion: "Northern Virginia"},
                {StackName: "Fqhv-Cxt-Neoload2020-11-10T21-41-10", StackStatus: "terminated", UserName: "v529879", TimeCreated: "2020-11-10 21:41:13.985002", NumLgs: "2", DisplayRegion: "Northern Virginia"},
                {StackName: "Fqhv-Cxt-Neoload2020-11-10T20-33-40", StackStatus: "terminated", UserName: "v529879", TimeCreated: "2020-11-10 20:33:46.637227", NumLgs: "2", DisplayRegion: "Northern Virginia"},
                {StackName: "Fqhv-Cxt-Neoload2020-11-10T20-16-30", StackStatus: "terminated", UserName: "v529879", TimeCreated: "2020-11-10 20:16:33.409705", NumLgs: "2", DisplayRegion: "Northern Virginia"},
                {StackName: "Fqhv-Cxt-Neoload2020-11-10T14-28-17", StackStatus: "terminated", UserName: "v529879", TimeCreated: "2020-11-10 14:28:20.811088", NumLgs: "2", DisplayRegion: "Northern Virginia"},
                {StackName: "Fqhv-Cxt-Neoload2020-11-09T20-17-15", StackStatus: "terminated", UserName: "v529879", TimeCreated: "2020-11-09 20:17:18.279889", NumLgs: "3", DisplayRegion: "Northern Virginia"},
                {StackName: "Fqhv-Cxt-Neoload2020-11-09T19-18-08", StackStatus: "terminated", UserName: "v529879", TimeCreated: "2020-11-09 19:18:11.105154", NumLgs: "3", DisplayRegion: "Northern Virginia"},
                {StackName: "Fqhv-Cxt-Neoload2020-11-09T01-37-24", StackStatus: "running", UserName: "v529879", TimeCreated: "2020-11-09 01:37:27.243305", NumLgs: "2", DisplayRegion: "Northern Virginia"}
            ];*/
            this.setState({loading: false});
            console.log(stacksResponse);
        } catch(err) {
            alert("Something went wrong with loading the stack data. Please contact the administrator.");
            console.log({err});
        }
        try {
            let newStacks = stacksResponse.map((stack) => {
                return {
                    stackName: stack.StackName,
                    status: stack.StackStatus,
                    launchedBy: stack.UserName,
                    launchedAt: stack.TimeCreated,
                    numLgs: stack.NumLgs,
                    region: stack.DisplayRegion
                }
            })
    
            console.log("newStacks: ", newStacks);

            this.setState({stacks: newStacks});
    
        } catch(err) {
            console.log({err});
        }
    }

    async componentDidMount() {
    //componentDidMount() {
        this.getStatus();
        // let stacksResponse;
        // try {
        //     this.setState({refresh: false});
        //     this.setState({loading: true});
        //     stacksResponse = await API.get('stackstatus', '/stackstatus');
        //     /*stacksResponse = [
        //         {StackName: "Fqhv-Cxt-Neoload2020-11-12T15-25-46", StackStatus: "running", UserName: "v529879", TimeCreated: "2020-11-12 15:25:49.876563", NumLgs: "2", DisplayRegion: "Northern Virginia"},
        //         {StackName: "Fqhv-Cxt-Neoload2020-11-11T17-19-00", StackStatus: "pending", UserName: "v529879", TimeCreated: "2020-11-11 17:19:03.338327", NumLgs: "2", DisplayRegion: "Northern Virginia"},
        //         {StackName: "Fqhv-Cxt-Neoload2020-11-11T15-37-14", StackStatus: "pending", UserName: "v529879", TimeCreated: "2020-11-11 15:37:17.879704", NumLgs: "2", DisplayRegion: "Northern Virginia"},
        //         {StackName: "Fqhv-Cxt-Neoload2020-11-10T22-30-58", StackStatus: "terminated", UserName: "v529879", TimeCreated: "2020-11-10 22:31:02.465658", NumLgs: "2", DisplayRegion: "Northern Virginia"},
        //         {StackName: "Fqhv-Cxt-Neoload2020-11-10T21-41-10", StackStatus: "terminated", UserName: "v529879", TimeCreated: "2020-11-10 21:41:13.985002", NumLgs: "2", DisplayRegion: "Northern Virginia"},
        //         {StackName: "Fqhv-Cxt-Neoload2020-11-10T20-33-40", StackStatus: "terminated", UserName: "v529879", TimeCreated: "2020-11-10 20:33:46.637227", NumLgs: "2", DisplayRegion: "Northern Virginia"},
        //         {StackName: "Fqhv-Cxt-Neoload2020-11-10T20-16-30", StackStatus: "terminated", UserName: "v529879", TimeCreated: "2020-11-10 20:16:33.409705", NumLgs: "2", DisplayRegion: "Northern Virginia"},
        //         {StackName: "Fqhv-Cxt-Neoload2020-11-10T14-28-17", StackStatus: "terminated", UserName: "v529879", TimeCreated: "2020-11-10 14:28:20.811088", NumLgs: "2", DisplayRegion: "Northern Virginia"},
        //         {StackName: "Fqhv-Cxt-Neoload2020-11-09T20-17-15", StackStatus: "terminated", UserName: "v529879", TimeCreated: "2020-11-09 20:17:18.279889", NumLgs: "3", DisplayRegion: "Northern Virginia"},
        //         {StackName: "Fqhv-Cxt-Neoload2020-11-09T19-18-08", StackStatus: "terminated", UserName: "v529879", TimeCreated: "2020-11-09 19:18:11.105154", NumLgs: "3", DisplayRegion: "Northern Virginia"},
        //         {StackName: "Fqhv-Cxt-Neoload2020-11-09T01-37-24", StackStatus: "running", UserName: "v529879", TimeCreated: "2020-11-09 01:37:27.243305", NumLgs: "2", DisplayRegion: "Northern Virginia"}
        //     ];*/
        //     this.setState({loading: false});
        //     console.log(stacksResponse);
        // } catch(err) {
        //     alert("Something went wrong with loading the stack data. Please contact the administrator.");
        //     console.log({err});
        // }
        // try {
        //     let newStacks = stacksResponse.map((stack) => {
        //         return {
        //             stackName: stack.StackName,
        //             status: stack.StackStatus,
        //             launchedBy: stack.UserName,
        //             launchedAt: stack.TimeCreated,
        //             numLgs: stack.NumLgs,
        //             region: stack.DisplayRegion
        //         }
        //     })
    
        //     console.log("newStacks: ", newStacks);

        //     this.setState({stacks: newStacks});
    
        // } catch(err) {
        //     console.log({err});
        // }
    }

    refreshHandler = () => this.setState({refresh: true});

    render() {
        const {loading} = this.state;

        return (
        <div>
            <div className={`section-heading`}>Stack Status <button className={`button refresh`} onClick={this.getStatus}><FontAwesomeIcon  icon="redo" /></button></div>
            {loading && <div className="loading"><FontAwesomeIcon  icon="spinner" spin/></div>}
            {!loading && 
            <div className={`table-cell`}>
                <MaterialTable
                    icons={tableIcons}
                    title=""
                    columns={[
                        {title: 'Stack Name', field: 'stackName'},
                        {title: 'Region', field: 'region'},
                        {title: 'Load Generators', field: 'numLgs'},
                        {title: 'Status', field: 'status',
                            render: rowData => {
                                return rowData.status == "pending" ? <p style={{ color: "#FF8c00", fontWeight: "bold" }}>{rowData.status}</p> :
                                    rowData.status == "running" ? <p style={{ color: "#008240", fontWeight: "bold" }}>{rowData.status}</p> :
                                    rowData.status == "stopping" ? <p style={{ color: "#A9A9A9", fontWeight: "bold" }}>{rowData.status}</p> :
                                        <p style={{ color: "#cd040b", fontWeight: "bold" }}>{rowData.status}</p>
                            }},
                        {title: 'Launched By', field: 'launchedBy'},
                        {title: 'Time Launched', field:'launchedAt'}
                    ]}
                    data={this.state.stacks}
                    options={{
                        headerStyle: {
                            backgroundColor: '#000000',
                            color: '#FFF',
                            fontFamily: 'Neue Haas Grotesk Text Pro',
                            fontWeight: 'bold'
                        }
                    }}
                />
            </div>}
        </div>
        );
    }
}

class Terminate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stackName : '',
            confirmed : false
        };
    }
    
    terminateInputHandler = event => this.setState({stackName: event.target.value});
    terminateInputTrim = event => this.setState({stackName: event.target.value.trim()});

    checkBoxHandler = () => {
        this.setState(state =>  {
            return {
                confirmed : !state.confirmed
            };
        });
    };
    
    //this.setState({confirmed: !this.state.confirmed});

    handleSubmit = async (event) => {
        event.preventDefault();
        
        const myInit = {
            body: {
                StackName: this.state.stackName,
                UserEmail: this.props.userInfo.attributes.email,
                UserName: this.props.userInfo.username
              }
        };
        console.log('myInit: ', myInit);
        try {
            const response = await API.post('cxtterminatestack', '/cxtterminatestack', myInit);
            console.log('response: ', response);
            alert("You submitted a termination request. You should get an email confirmation in a few minutes.");
            //return response;
        } catch (err) {
            console.log({err});
            alert("For some reason your termination request failed. Please contact the administrator.");
        }
        
        this.setState({ 
            stackName : '',
            confirmed: false
        });
    };

    isSubmitEnabled = () => {
        if(this.state.confirmed && this.state.stackName != ''){
            return true;
        }
        return false;
    }

    render() {
        const isEnabled = this.isSubmitEnabled();
        return (
            <form onSubmit={this.handleSubmit}>
                <div className={`section-heading`}>Terminate</div>
                <div className={`tab-content`}>
                    <div className={`content-item`}>         
                        <label>Enter Stack Name for Termination: {' '}
                            <input
                                className={`textbox`}
                                type="text"
                                value={this.state.stackName}
                                onChange={this.terminateInputHandler}
                                onBlur={this.terminateInputTrim}
                            />
                        </label>
                    </div>
                    <div className={`content-item`}>
                        <label>I acknowledge that I am terminating Neoload LGs in this stack.
                            <input
                                name="confirmLaunch"
                                type="checkbox"
                                checked={this.state.confirmed}
                                onChange= {this.checkBoxHandler} />
                        </label>
                    </div>
                    <div>
                        <button className={`button`} disabled={!isEnabled}>Terminate Hosts</button>
                    </div>
                </div>
            </form>
        );
        
    }
}

class HostForm extends Component {
    constructor(props) {
        super(props);
        this.state =  { 
            numHosts: '',
            confirmed: false,
            isHostOverride: false,
            domains: [''],
            ipAddresses: [''],
            hostFileUpdates: 0,
            region: 'Northern Virginia (East 1)'
        };
    }

    changeHandler = event => this.setState({numHosts: event.target.value});
    checkBoxHandler = event => this.setState({confirmed: !this.state.confirmed});

    domainChangeHandler = (i, event) => {
        //this.setState({domains: event.target.value});
        event.persist();
        this.setState(state => {
            let newDomains = [...state.domains];
            newDomains[i] = event.target.value;
            console.log("domains: ", newDomains);
            return {
                domains: newDomains
            };
        });
    };

    domainChangeTrim = (i, event) => {
        //this.setState({domains: event.target.value});
        event.persist();
        this.setState(state => {
            let newDomains = [...state.domains];
            newDomains[i] = event.target.value.trim();
            console.log("domains: ", newDomains);
            return {
                domains: newDomains
            };
        });
    };

    ipAddressChangeHandler = (i, event) => {
        //this.setState({ipAddresses: event.target.value});
        event.persist();
        this.setState(state => {
            let newIpAddresses = [...state.ipAddresses];
            newIpAddresses[i] = event.target.value;
            console.log("ip addresses: ", newIpAddresses);
            return {
                ipAddresses: newIpAddresses
            };
        });
    };

    ipAddressChangeTrim = (i, event) => {
        //this.setState({ipAddresses: event.target.value});
        event.persist();
        this.setState(state => {
            let newIpAddresses = [...state.ipAddresses];
            newIpAddresses[i] = event.target.value.trim();
            console.log("ip addresses: ", newIpAddresses);
            return {
                ipAddresses: newIpAddresses
            };
        });
    };
    
    hostFileUpdateIncrementHandler = () => {
        this.setState(state =>  {
            if (state.hostFileUpdates < maxHostUpdate) {
                const newDomains = state.domains.concat('');
                const newIpAddresses = state.ipAddresses.concat('');
                console.log("stage ips: ", state.ipAddresses);
                console.log("ips length: ", state.ipAddresses.length);
                console.log("stage domains: ", state.domains);
                console.log("domain length: ", state.domains.length);
                console.log("hostfileupdates: ", state.hostFileUpdates);
                return {
                    domains: newDomains,
                    ipAddresses: newIpAddresses,
                    hostFileUpdates: state.hostFileUpdates + 1
                };
            }
        });
    };

    hostFileUpdateDecrementHandler = () => {
        this.setState(state =>  {
            if (state.hostFileUpdates > minHostUpdate) {
                state.domains.pop();
                state.ipAddresses.pop();
                const newDomains = state.domains;
                const newIpaddresses = state.ipAddresses;
                console.log("stage pop domains: ", newDomains);
                console.log("stage pop ips: ", newIpaddresses);
                return {
                    domains: newDomains,
                    ipAddresses: newIpaddresses,
                    hostFileUpdates: state.hostFileUpdates - 1
                };
            }   
        });
    };

    regionHandler = event => this.setState({region: event.target.value});
    
    handleSubmit = async (event) => {
        event.preventDefault();

        const myInit = {
            body: {
                NumLgs: this.state.numHosts,
                Domains: this.state.domains,
                IpAddresses: this.state.ipAddresses,
                Region: this.state.region,
                IsHostOverride: this.state.isHostOverride,
                UserEmail: this.props.userInfo.attributes.email,
                UserName: this.props.userInfo.username
              }
        };
        console.log('myInit: ', myInit);
        try {
            const response = await API.post('cxtneoloadlg', '/cxtneoloadlg', myInit);
            console.log('response: ', response);
            console.log('stack id: ', response.stack_id);
            //if response provides valid stack name, then call graphql to update database with premlinary 
            const lgStackDetails = {
                numLgs: this.state.numHosts,
                stackId: response.stack_id,
                stackName: response.stack_name,
                status: response.stack_status,
                userEmail: this.props.userInfo.attributes.email,
                userName: this.props.userInfo.username
            };
            /*const newLgStack = await API.graphql({query: mutations.createLgStack, variables: {input: lgStackDetails}});*/
            lgStackDetails.domains = this.state.domains;
            lgStackDetails.ipAddresses = this.state.ipAddresses;
            console.log(lgStackDetails);
            //const hostOverrides = await API.post('hostoverrides', '/hostoverrides', lgStackDetails)
            alert("You just launched your instances. You should receive a confirmation email in a few minutes.");
            //return response;
        } catch (err) {
            console.log({err});
            alert("For some reason your instances failed to launch. Please contact the administrator.");
        }
        
        this.setState({ 
            numHosts: '',
            confirmed: false
        });
    };

    onChangeRadio = (event) => {
        event.persist();
        this.setState(() => {
            if (event.target.value == "Yes"){
                return {
                    isHostOverride: true,
                };
            } else if (event.target.value == "No") {
                return {
                    isHostOverride: false,
                    hostFileUpdates: 0,
                    domains: [''],
                    ipAddresses: ['']
                };
            }
        });
    };

    isSubmitEnabled = () => {
        if(this.state.confirmed && this.state.numHosts != ''){
            return true;
        }
        return false;
    }

    render() {
      const isEnabled = this.isSubmitEnabled();
      console.log("domains: ", this.state.domains);

      return (
        <form onSubmit={this.handleSubmit}>
            <div className={`section-heading`}>Launch Neoload Hosts</div>
            <div className={`tab-content`}>
                <div className={`content-item`}>         
                    <label>Enter Number of Neoload Hosts: {' '}
                        <input
                            className={`textbox`}
                            type="number"
                            value={this.state.numHosts}
                            min="1"
                            max="220"
                            onChange={this.changeHandler}
                        />
                    </label>
                </div>
                <div className={`content-item`}>
                    <label>Region: </label>
                    <select className={`custom-select`} value={this.state.region} onChange={this.regionHandler}>
                        <option name="us-east-1">Northern Virginia (East 1)</option>
                        <option name="us-east-2">Ohio (East 2)</option>
                        <option name="us-west-1">Northern California (West 1)</option>
                        <option name="us-west-2">Oregon (West 2)</option>
                    </select>
                </div>
                <div className={`content-item`}>
                    <b>Are Host Overrides Required?</b>
                    <div onChange={this.onChangeRadio}>
                        <input type="radio" value="Yes" name="choice" /> Yes
                        <input type="radio" value="No" name="choice" defaultChecked/> No
                    </div>
                </div>
                <div className={`content-item`}>
                    <b>Host Overrides</b>
                    <div>
                        <button className={`button addremove`} disabled={!this.state.isHostOverride} type = "button" onClick={this.hostFileUpdateIncrementHandler}>+</button>
                        {' '}
                        <button className={`button addremove`} disabled={!this.state.isHostOverride} type = "button" onClick={this.hostFileUpdateDecrementHandler}>-</button>
                    </div>
                    <div>
                        {utils.range(0, this.state.hostFileUpdates).map((hostFileUpdate) => (
                            <div className={`host-override-unit`}>
                                <label>Domain Name: {' '}
                                    <input 
                                        className={`host-override-input textbox`}
                                        type="text"
                                        value={this.state.domains[hostFileUpdate]}
                                        onChange={(e) => this.domainChangeHandler(hostFileUpdate, e)}
                                        onBlur={(e) => this.domainChangeTrim(hostFileUpdate, e)}
                                        disabled={!this.state.isHostOverride}
                                    />
                                </label>
                                {'   '}
                                <label>IP Address: {' '}
                                    <input
                                        className={`host-override-input textbox`}
                                        type="text"
                                        value={this.state.ipAddresses[hostFileUpdate]}
                                        onChange={(e) => this.ipAddressChangeHandler(hostFileUpdate, e)}
                                        onBlur={(e) => this.ipAddressChangeTrim(hostFileUpdate, e)}
                                        disabled={!this.state.isHostOverride}
                                    />
                                </label>
                            </div>
                        ))}
                    </div>
                </div>
                <div className={`content-item`}>
                    <label>I acknowledge that I am launching Neoload LGs on EC2 Instances.
                        <input
                            name="confirmLaunch"
                            type="checkbox"
                            checked={this.state.confirmed}
                            onChange= {this.checkBoxHandler} />
                    </label>
                </div>
                <div className={`content-item`}>
                    <button className={`button`} disabled={!isEnabled}>Add Hosts</button>
                </div>
            </div>
        </form>
      );
    }
  }

  class HostFileForm extends Component {

    render() {
        
        return (
            <div>
              <div>            
                  <label>Domain Name: {' '}
                      <input
                          type="text"
                          value={this.props.domain}
                          onChange={this.props.domainChangeHandler}
                      />
                  </label>
                  <label>IP Address: {' '}
                      <input
                          type="text"
                          value={this.props.ipAddress}
                          onChange={this.props.ipAddressChangeHandler}
                      />
                  </label>
              </div>
            </div>
        );
    }
  }

export default withAuthenticator(App)
