/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:adb99567-946b-41d3-96ec-e6f33a4023ea",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_KDvcI9Iy1",
    "aws_user_pools_web_client_id": "7j08hsa5qc9a42kgdub5d6f9k2",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "cxtneoloadlg",
            "endpoint": "https://l8ovovb4rk.execute-api.us-east-1.amazonaws.com/devvv",
            "region": "us-east-1"
        },
        {
            "name": "cxtterminatestack",
            "endpoint": "https://00xolsrds4.execute-api.us-east-1.amazonaws.com/devvv",
            "region": "us-east-1"
        },
        {
            "name": "stackstatus",
            "endpoint": "https://0kzevgwwyk.execute-api.us-east-1.amazonaws.com/devvv",
            "region": "us-east-1"
        },
        {
            "name": "instances",
            "endpoint": "https://m5sy2l1qt2.execute-api.us-east-1.amazonaws.com/devvv",
            "region": "us-east-1"
        }
    ]
};


export default awsmobile;
